<template>
  <Drawer
    v-model:visible="isRightSidebarOpen"
    position="right"
    :pt="{ header: { class: 'justify-content-between' } }"
  >
    <template #header>
      <div>
        <h4 class="text-slate-500 font-bold text-lg">Filtros</h4>
      </div>
    </template>
    <div class="relative h-full">
      <Listbox
        v-model="selectedFilters"
        :options="itemsToFilter"
        @change="notify"
        multiple
        optionLabel="label"
        optionGroupLabel="value"
        optionGroupChildren="items"
        :pt="{
          listContainer: { class: '!max-h-none' },
          root: { class: 'border-transparent' },
          optionGroup: { class: 'bg-orange-500 text-white rounded' },
          option: { class: 'pl-4' },
        }"
      >
        <template #optiongroup="slotProps">
          <span class="px-2">{{ slotProps.option.value }}</span>
        </template>
      </Listbox>
      <Loader :isVisible="isLoading" :fullRelative="true"></Loader>
    </div>
  </Drawer>
</template>

<script setup>
import Drawer from "primevue/drawer";
import Listbox from "primevue/listbox";
import { ref, onMounted, toRaw } from "vue";
import Loader from "../Loader.vue";
import { useDropdownsStore } from "../../stores/dropdowns";
import { getDropdowns } from "../../services/dropdowns";
import { useGlobalStore } from "../../stores/global";
import { storeToRefs } from "pinia";

import { useSidebar } from "../../composables/useSidebar";

const { isRightSidebarOpen } = useSidebar();

const globalStore = useGlobalStore();
const selectedFilters = ref([]);

const isLoading = ref(false);

const itemsToFilter = ref([]);

const dropdownsStore = useDropdownsStore();

const notify = (v) => {
  const filters = toRaw(v.value.map((x) => toRaw(x)));

  globalStore.notify(filters);
};

onMounted(async () => {
  if (!dropdownsStore.hasBeenLoaded()) {
    try {
      isLoading.value = true;
      const dropdowns = await getDropdowns();
      dropdownsStore.setDropdowns(dropdowns.data);
    } finally {
      isLoading.value = false;
    }
  }
  let items = [
    {
      label: "c_cot_status",
      value: "Estado COT",
      items: [
        {
          label: "Aprobada",
          value: "approved",
          grouping: "c_cot_status",
        },
        {
          label: "En espera",
          value: "waiting",
          grouping: "c_cot_status",
        },
      ],
    },
    {
      label: "service_type",
      value: "Tipo de servicio",
      items: [],
    },
    // TODO: Añadir estos filtros
    // {
    //   label: "tag_service_type",
    //   value: "Tags servicio",
    //   items: [],
    // },
  ];

  const { dropdowns } = storeToRefs(dropdownsStore);

  itemsToFilter.value = dropdowns.value.reduce((acc, curr) => {
    if (
      // curr.grouping === "tag_service_type" ||
      curr.grouping === "service_type" ||
      curr.grouping === "c_cot_status"
    ) {
      const grouping = acc.find((g) => g.label === curr.grouping);
      if (grouping) {
        grouping.items.push(curr);
      }
    }

    return acc;
  }, items);
});
</script>
