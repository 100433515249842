import { storeToRefs } from "pinia";
import { useDialogStore } from "../stores/dialog";

import _ from "lodash";
import { useCotsStore } from "../stores/cots";
const defaultOptions = {
  isVisible: false,
  title: null,
  titleIcon: null,
  contentClass: null,
  headerClass: null,
  titleIconExtraClass: "hidden",
  assemblyData: null,
  roleUser: "general",
};

export function useItemDialog() {
  const dialogStore = useDialogStore();
  const cotsStore = useCotsStore();

  const openItemDialog = (options, assemblyData) => {
    dialogStore.openModal(options, assemblyData);
    return { ...dialogStore.$state };
  };

  const closeItemDialog = () => {
    dialogStore.closeModal(defaultOptions);
    return { ...defaultOptions };
  };

  function updateAssemblyDataDialog(id, newContent) {
    const { newValue } = cotsStore.updateAssemblyStoreData(id, newContent);
    dialogStore.updateAssemblyData(newValue);
  }

  const dialogState = dialogStore.$state;

  return {
    openItemDialog,
    closeItemDialog,
    dialogState,
    updateAssemblyDataDialog,
  };
}
