<template>
  <Dialog
    v-model:visible="dialogStore.isVisible"
    @update:visible="closeItemDialog"
    :draggable="false"
    modal
    :pt="{
      root: { class: 'w-9/12 h-5/6 bg-white overflow-y-scroll' },
      content: { class: 'h-full' },
      header: { class: 'flex items-center justify-between gap-4 ' },
    }"
  >
    <template #header>
      <i
        class="pi pi-database text-green-600"
        :class="dialogState.titleIconExtraClass"
        style="font-size: 1.8rem"
      >
      </i>
      <div class="text-2xl text-gray-800 font-extrabold">
        {{ dialogState.title }}
      </div>
    </template>

    <ProgressTimeline :assemblyData="dialogState.assemblyData" />

    <div class="dialog__content">
      <GeneralSection
        :assemblyData="dialogState.assemblyData"
        :roleUser="dialogState.roleUser"
      ></GeneralSection>
      <ReportsSection
        :assemblyData="dialogState.assemblyData"
        :roleUser="dialogState.roleUser"
      ></ReportsSection>

      <!-- Section for role customized UI -->
      <slot
        name="roleSection"
        v-bind="{
          assemblyData: dialogState.assemblyData,
          role: dialogState.roleUser,
        }"
      ></slot>

      <FooterSection :assemblyData="dialogState.assemblyData"></FooterSection>
    </div>
  </Dialog>
</template>
<script setup>
import Dialog from "primevue/dialog";

import { useItemDialog } from "../../composables/useItemDialog";
import { useDialogStore } from "../../stores/dialog";

import ProgressTimeline from "./ProgressTimeline.vue";

const dialogStore = useDialogStore();

const { closeItemDialog, dialogState } = useItemDialog();

import GeneralSection from "./GeneralSection.vue";
import ReportsSection from "./ReportsSection.vue";

import FooterSection from "./FooterSection.vue";
</script>
