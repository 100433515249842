<template>
  <div class="flex flex-col" v-if="visible">
    <div class="text-base font-semibold text-gray-800" :class="extraClassLabel">
      {{ label }}
    </div>
    <InputText
      v-if="inputType === 'inputText'"
      v-model="model"
      :id="id"
      :disabled="disabled"
      @update:modelValue="onUpdateValue"
    />
    <Select
      v-else-if="inputType === types.SELECT"
      v-model="model"
      :id="id"
      :options="dropdownOptions"
      :optionLabel="dropdownOptionLabel"
      :disabled="disabled"
      @change="onUpdateValue"
    />
    <Textarea
      v-else-if="inputType === types.TEXT_AREA"
      v-model="model"
      :id="id"
      rows="4"
      cols="30"
      @input="onUpdateValue"
      :disabled="disabled"
      autoResize
    />
    <DatePicker
      v-else-if="inputType === types.DATE"
      v-model="model"
      :id="id"
      @date-select="onUpdateValue"
      @hide="onUpdateValue"
      dateFormat="DD, d 'de' M 'del' yy"
      :disabled="disabled"
      showIcon
      class="p-0 shadow-none"
    >
    </DatePicker>
    <div v-else class="bg-red-500 text-white">
      Aún no hay compatibilidad para este tipo de Input: {{ inputType }}
    </div>
  </div>
</template>
<script setup>
import InputText from "primevue/inputtext";
import Select from "primevue/select";
import Textarea from "primevue/textarea";

import DatePicker from "primevue/datepicker";

import types from "../../../enums/modalTypes";

const props = defineProps({
  id: { required: true },
  inputType: { required: true },
  label: { required: true },
  disabled: { default: false, required: false },
  visible: { default: true, required: false },
  required: { default: false, required: false },
  extraClassLabel: { type: String, default: "" },
  dropdownOptions: { type: Object, default: {}, required: false },
  dropdownOptionLabel: String,
});

const model = defineModel();

const emit = defineEmits(["onEscape", "onEnter", "onChange", "onBlur"]);

const onUpdateValue = (e) => {
  emit("onChange");
};
</script>
