const modalTypes = Object.freeze({
  TEXT: "inputText",
  SELECT: "select",
  TEXT_AREA: "textArea",
  DATE: "datePicker",
  NUMBER: "inputNumber",
  LIST_BOX: "listBox",
  RADIO_BUTTON: "radioButton",
});

export default modalTypes;
