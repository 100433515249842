import { ref } from "vue";

export function useNavLinks() {
  const menuItems = ref([
    {
      label: "Dashboard",
      href: "/",
      key: "dashboard",
      icon: "pi pi-table",
    },
    {
      label: "Calendario",
      href: "/calendar",
      key: "calendar",
      icon: "pi pi-calendar",
    },
    {
      label: "Comercial",
      href: "/comercial",
      key: "comercial",
      icon: "pi pi-wallet",
    },
    {
      label: "Contabilidad",
      href: "/contabilidad",
      key: "contabilidad",
      icon: "pi pi-calculator",
    },
    {
      label: "Logistica",
      href: "/logistica",
      key: "logistica",
      icon: "pi pi-car",
    },
    {
      label: "Talento Humano",
      href: "/talentohumano",
      key: "talentohumano",
      icon: "pi pi-heart",
    },
    {
      label: "Actas",
      href: "/actas",
      key: "actas",
      icon: "pi pi-file",
    },
  ]);

  return { menuItems };
}
