import { alegraClient } from "../data/alegraClient";
import { JogetClient } from "../data/jogetClient";

export const updateInvoice = (invoice) => {};

export const getInvoicesById = (id) => {
  return alegraClient.get("invoices", { params: { id } });
};

export const updateInvoiceJoget = (updatedInvoice) => {
  return JogetClient.put(
    import.meta.env.VITE_API_INVOICES_CREATE,
    updatedInvoice
  );
};

export const getInvoices = (pageSize = 100) => {
  return JogetClient.get(import.meta.env.VITE_API_INVOICES, {
    params: { pageSize },
  });
};

export const getInvoicesByAssemblyCot = ({ cot, pageSize }) => {
  if (!pageSize) pageSize = 100;
  if (!cot) return [];

  return JogetClient.get(import.meta.env.VITE_API_INVOICES, {
    params: { id_cot: cot, pageSize },
  });
};
