import { JogetClient } from "../data/jogetClient";
import { getWeek } from "../utils/dateformatter";
import { useDayjs } from "../composables/useDayjs";

const dayjs = useDayjs();

export const updateAssembly = (data) => {
  return JogetClient.put(import.meta.env.VITE_API_COT_FINAL, data);
};

export const updateAssemblyService = (data) => {
  return JogetClient.put(import.meta.env.VITE_API_SOL_COT_SERVICE, data);
};

export const updateAssemblyClient = (data) => {
  return JogetClient.put(import.meta.env.VITE_API_SOL_COT_CLIENT, data);
};

export const getAssemblies = ({ pageSize, year, startOffSet }) => {
  if (!pageSize) pageSize = 1000;
  if (!year) year = new Date().getFullYear();
  if (!startOffSet) startOffSet = 0;

  return JogetClient.get(import.meta.env.VITE_API_BASIC, {
    params: {
      "d-5356945-fn_year": year,
      startOffset: startOffSet,
      pageSize,
    },
  });
};

export const getAssembliesRange = ({ pageSize, from, to }) => {
  if (!pageSize) pageSize = 35;

  const cw = getWeek();

  const firstDayWeek = dayjs(cw[0], "YYYY-MM-DD");
  const lastDayWeek = dayjs(cw[cw.length - 1], "YYYY-MM-DD");

  if (!from) from = dayjs(firstDayWeek).format("DD-MM-YYYY");
  if (!to) to = dayjs(lastDayWeek).format("DD-MM-YYYY");

  return JogetClient.get(import.meta.env.VITE_API_BASIC_RANGE, {
    params: {
      "d-4119243-fn_fecha_inicio": from,
      "d-4119243-fn_fecha_final": to,
      pageSize,
    },
  });
};

export const getAssembliesSecondAnnouncementRange = ({
  pageSize,
  from,
  to,
}) => {
  if (!pageSize) pageSize = 35;

  const cw = getWeek();

  const firstDayWeek = dayjs(cw[0], "YYYY-MM-DD");
  const lastDayWeek = dayjs(cw[cw.length - 1], "YYYY-MM-DD");

  if (!from) from = dayjs(firstDayWeek).format("DD-MM-YYYY");
  if (!to) to = dayjs(lastDayWeek).format("DD-MM-YYYY");

  return JogetClient.get(import.meta.env.VITE_API_BASIC_RANGE_SECOND, {
    params: {
      "d-7849962-fn_fecha_inicio": from,
      "d-7849962-fn_fecha_final": to,
      pageSize,
    },
  });
};
