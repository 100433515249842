// Vue
import { createApp } from "vue";
import App from "./App.vue";
// Prime Vue
import router from "./router";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faWallet,
  faCircleExclamation,
  faMoneyBills,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Card from "primevue/card";

import "../src/assets/styles/Style.scss";

// Pinia
import { createPinia } from "pinia";

library.add(faWallet);
library.add(faMoneyBills);
library.add(faCircleExclamation);

// Create preset
const OrangePreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: "#FFEFE6", // muy claro, fondo ligero
      100: "#FFD8C7", // claro
      200: "#FFB89A", // medio claro
      300: "#FF8F63", // medio
      400: "#FF7039", // oscuro
      500: "#F2600C", // principal, naranja casi rojo
      600: "#D9550A", // más oscuro, hover
      700: "#BF4909", // aún más oscuro
      800: "#A43D07", // profundo
      900: "#7E2E05", // muy oscuro
      950: "#4F1C03", // casi negro
    },
    colorScheme: {
      light: {
        primary: {
          color: "#f39516", // principal, naranja casi rojo
          inverseColor: "#ffffff", // blanco como color inverso
          hoverColor: "#D9550A", // tono más oscuro para hover
          activeColor: "#BF4909", // tono oscuro para active
        },
        highlight: {
          background: "#FFD8C7", // fondo claro para resaltes
          focusBackground: "#FF8F63", // foco medio
          color: "#212121", // texto oscuro
          focusColor: "#ffffff", // blanco para foco de color
        },
      },
      dark: {
        primary: {
          color: "#FFD8C7", // claro para modo oscuro
          inverseColor: "#4F1C03", // casi negro para contraste
          hoverColor: "#FF7039", // tono medio claro en hover
          activeColor: "#F2600C", // naranja casi rojo en active
        },
        highlight: {
          background: "rgba(242, 96, 12, .16)", // translúcido para fondo de resalte
          focusBackground: "rgba(217, 85, 10, .24)", // translúcido más intenso
          color: "rgba(255,255,255,.87)", // texto blanco con opacidad
          focusColor: "rgba(255,255,255,.87)", // blanco en foco
        },
      },
    },
  },
});

createApp(App)
  .use(PrimeVue, {
    theme: {
      preset: OrangePreset,
      options: {
        darkModeSelector: "",
      },
    },
    zIndex: {
      modal: 1100, //dialog, sidebar
      overlay: 1200, //dropdown, overlaypanel
      menu: 1000, //overlay menus
      tooltip: 1102, //tooltip
    },
    inputStyle: "filled",
    ripple: true,
    locale: {
      dayNames: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Lun", "Mar", "Miér", "Jue", "Vie", "Sáb"],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      monthNamesShort: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
    },
  })
  .use(createPinia())
  .use(router)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("Button", Button)
  .component("InputText", InputText)
  .component("Tag", Tag)
  .component("Card", Card)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
