<template>
  <Timeline class="h-26 ml-6" :value="events" layout="horizontal" align="top">
    <template #marker="{ item }">
      <div
        class="h-1rem w-1rem rounded-full"
        :class="assemblyData[item?.key] ? 'bg-[#8C6B40]' : 'bg-gray-400'"
      ></div>
    </template>
    <template #connector="{ item }">
      <div
        style="height: 3px; width: 100%"
        :class="assemblyData[item?.key] ? 'bg-[#8C6B40]' : 'bg-gray-400'"
      ></div>
    </template>
    <template #content="{ item }">
      <div class="">
        {{ item.name }}
      </div>
    </template>
  </Timeline>
</template>

<script setup>
const props = defineProps({
  assemblyData: {
    required: true,
    type: Object,
    default: {},
  },
});

import Timeline from "primevue/timeline";

import { useProgressCot } from "../../composables/useProgressCot";

const { events } = useProgressCot();
</script>
