<template>
  <div class="flex flex-col">
    <div class="text-base font-semibold text-gray-800">
      Correo envia cotización
    </div>
    <InputText
      v-model="model"
      id="assemblyServiceClientMail"
      @update:modelValue="showUpdateFormButton = true"
    />
  </div>
  <Button
    label="Guardar correo"
    icon="pi pi-check"
    :class="buttonSaveClass"
    @click="saveComercialData"
  />
</template>

<script setup>
import { ref, computed } from "vue";
import { updateAssemblyService } from "../../services/assembly";
import { useItemDialog } from "../../composables/useItemDialog";

const props = defineProps({
  assemblyData: {
    type: Object,
    default: {},
    required: true,
  },
  permissions: {
    type: Object,
    default: {},
    required: true,
  },
});

const emit = defineEmits(["loadSection"]);

const { updateAssemblyDataDialog } = useItemDialog();

const showUpdateFormButton = ref(false);
const model = ref(props.assemblyData?.assemblyServiceClientMail ?? "");

const buttonSaveClass = computed(() => {
  return { hidden: !showUpdateFormButton.value };
});

const saveComercialData = async () => {
  const requestData = {
    id: props.assemblyData.assemblyCotNumber,
    correo_cotizante: model.value,
  };

  try {
    emit("loadSection", true);

    const response = await updateAssemblyService(requestData);

    updateAssemblyDataDialog(props.assemblyData.assemblyId, {
      assemblyServiceClientMail: model.value,
    });

    console.log(response);
  } catch (error) {
    console.error(error);
  } finally {
    emit("loadSection", false);
    showUpdateFormButton.value = false;
  }
};
</script>
