import axios from "axios";

const alegraClient = axios.create({
  baseURL: "https://api.alegra.com/api/v1/",
  timeout: 10000,
  headers: {
    accept: "application/json",
    authorization: import.meta.env.VITE_ALEGRA_API_KEY,
  },
});

alegraClient.interceptors.response.use(
  (response) => ({
    data: response.data,
    size: response.data?.length ?? 0,
  }),
  (error) => {
    let errorData = error;

    if (error.response?.data) {
      errorData = error.response.data;
    }

    return Promise.reject(errorData);
  }
);

export { alegraClient };
