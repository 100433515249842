import { toRaw } from "vue";
import { useDayjs } from "../composables/useDayjs";

const dayjs = useDayjs();

const prefix = "COT-";

export default function (items, { signatures, dropdowns }) {
  return items.map((item) => ({
    ...item,
    assemblyCotNumberFormatted: item.assemblyCotNumber.substring(prefix.length),
    assemblyComercialStatusColor:
      item.assemblyStatus === "approved" ? "bg-green-300" : "bg-white",
    assemblyContabilidadStatusColor:
      item.assemblyContabilidadStatus ?? "bg-white",
    assemblyLogisticaStatusColor: item.assemblyLogisticaStatus ?? "bg-white",
    assemblyTalentoStatusColor: item.assemblyTalentoStatus ?? "bg-white",
    assemblyComercialStatus: item.assemblyStatus === "approved",
    assemblyContabilidadStatus:
      item.assemblyContabilidadStatus === "bg-green-300",
    assemblyLogisticaStatus: item.assemblyLogisticaStatus === "bg-green-300",
    assemblyTalentoStatus: item.assemblyTalentoStatus === "bg-green-300",
    assemblyServiceType: mapDropdownsKeys(item.version, item, dropdowns) ?? "",
    assemblyServiceTypeFormatted:
      mapDropdownsKeys(item.version, item, dropdowns)?.label ?? "",
    dbStatus: item.assemblyRealParticipants > 0,
    assemblyServiceTags: toRaw(
      item.assemblyServiceTags.map((tag) =>
        toRaw(dropdowns.find((t) => t.value === tag))
      )
    ),
    assemblyDateCreatedFormatted: dayjs(item.assemblyDateCreated).format(
      "D [de] MMMM[,] YYYY [a las] hh:mm"
    ),
    assemblyStatusFormatted:
      item.assemblyStatus === "approved" ? "Aprobado" : "Pendiente",
    assemblyClientAdvanceStatusFormatted:
      item.assemblyClientAdvanceStatus === "recibido"
        ? "Recibido"
        : "No recibido",
    assemblyServiceFirstAnnoucementDateFormatted: dayjs(
      item.assemblyServiceFirstAnnoucementDate,
      "YYYY-MM-DD hh:mm a"
    ).format("D [de] MMM [a las] hh:mm a"),
    assemblyApprovedBy:
      signatures.find((sg) => sg.id === item.assemblyApprovedBy) || "",
    reportsReviewedBy:
      signatures.find((sg) => sg.id === item.reportsReviewedBy) || "",
    reportsSentBy: signatures.find((sg) => sg.id === item.reportsSentBy) || "",
    reportsReviewedByFormatted:
      signatures.find((sg) => sg.id === item.reportsReviewedBy)?.sig_name || "",
    assemblyApprovedByFormatted:
      signatures.find((sg) => sg.id === item.assemblyApprovedBy)?.sig_name ||
      "",
    assemblyServiceSecondAnnoucementDateFormatted:
      item.assemblyServiceSecondAnnoucementDate
        ? dayjs(
            item.assemblyServiceSecondAnnoucementDate,
            "YYYY-MM-DD hh:mm a"
          ).format("D [de] MMM [a las] hh:mm a")
        : "",
    assemblyServiceDateFinishedFormatted: !!item.assemblyServiceDateFinished
      ? dayjs(item.assemblyServiceDateFinished).format("D MMM hh:mm a")
      : "",
  }));
}

const mapDropdownsKeys = (version, asamblea, dropdowns) => {
  if (version === "v1") {
    return dropdowns.find(
      (dp) =>
        dp.grouping === "tag_service_type" &&
        dp.value === asamblea.assemblyServiceType
    );
  } else if (version === "v2") {
    return dropdowns.find(
      (dp) =>
        dp.grouping === "service_type" &&
        dp.value === asamblea.assemblyServiceType
    );
  }
};
