import { defineStore } from "pinia";

export const useDropdownsStore = defineStore("dropdowns", {
  state: () => ({
    dropdowns: [],
    loaded: false,
  }),
  actions: {
    setDropdowns(dp) {
      this.dropdowns = dp;
      this.loaded = true;
    },
    hasBeenLoaded() {
      return this.loaded;
    },
  },
});
