<template>
  <Button
    class="flex items-center justify-center w-full border-transparent"
    :class="itemClass"
    raised
    @click="openNewTab"
  >
    <template #icon>
      <img width="80" :src="image" />
    </template>
  </Button>
</template>
<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
    default: () => "#",
  },
  image: {
    type: String,
    required: true,
    default: () => "Logo-clase-alfa-nosotros.png",
  },
  itemClass: String,
});

const openNewTab = () => {
  window.open(props.url, "_blank");
};
</script>
