import fields from "../enums/field";
import modalCategories from "../enums/modalCategory";
import datatableTypes from "../enums/datatableTypes";
import modalTypes from "../enums/modalTypes";
import gridItemsTypes from "../enums/gridItemTypes";

const dataTableColumns = [
  {
    field: fields.assemblyCotNumber,
    header: "COT",
    dataTableType: datatableTypes.TEXT,
    link: "https://joget.clasealpha.com:8443/jw/web/userview/acomercial2022/graficos/_/cot_final_crud?_mode=edit&id=",
    frozen: true,
  },
  {
    field: fields.assemblyClientOrganizationName,
    header: "Cliente",
    dataTableType: datatableTypes.TEXT,
    frozen: true,
  },
  {
    field: fields.assemblyServiceFirstAnnoucementDate,
    header: "Fecha 1ra convocatoria",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.reportsSendWoPayment,
    header: "¿Enviar informes sin pago?",
    color: "text-red-600",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyServiceSecondAnnoucementDate,
    header: "Fecha 2da convocatoria",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyServiceAddress,
    header: "Dirección",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyRealParticipants,
    header: "Usuarios reales",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyValuedParticipants,
    header: "Usuarios cotizados",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyClientAdminTel,
    header: "Celular de contacto",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
];

const generalSection = [
  {
    field: fields.assemblyCotNumber,
    header: "COT",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.LINK,
    link: "https://joget.clasealpha.com:8443/jw/web/userview/acomercial2022/graficos/_/cot_final_crud?_mode=edit&id=",
  },
  {
    field: fields.assemblyClientOrganizationName,
    header: "Cliente",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceFirstAnnoucementDate,
    header: "Fecha 1ra convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.reportsSendWoPayment,
    header: "¿Enviar informes sin pago?",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
    color: "text-red-600",
  },
  {
    field: fields.invoicesMailShouldSend,
    header: "Correo al que se debe enviar factura",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceSecondAnnoucementDate,
    header: "Fecha 2da convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceType,
    header: "Modalidad",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceAddress,
    header: "Dirección",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyRealParticipants,
    header: "Usuarios reales",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientAdminName,
    header: "Administrador",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyDateCreated,
    header: "Fecha que se agenda",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyValuedParticipants,
    header: "Usuarios cotizados",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientAdminTel,
    header: "Celular de contacto",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.comercialSendReportsMail,
    header: "Correo al que se debe enviar informes",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },

  {
    field: fields.assemblyServiceClientMail,
    header: "Correo envia cotización",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
];

const reportsSection = [
  {
    value: fields.reportsDateSend,
    label: "Fecha de envio",
    readonly: true,
    inputType: modalTypes.DATE,
  },
  {
    value: fields.assemblyServiceReportsMail,
    label: "Correo informes",
    readonly: false,
    inputType: modalTypes.TEXT,
  },
  {
    value: fields.reportsSentBy,
    label: "Enviado por",
    readonly: false,
    inputType: modalTypes.SELECT,
  },
  {
    value: fields.reportsReviewedBy,
    label: "Revisado por",
    readonly: false,
    inputType: modalTypes.SELECT,
  },
  {
    value: fields.reportsRemarks,
    label: "Anotaciones",
    readonly: false,
    inputType: modalTypes.TEXT_AREA,
  },
];

const roleSection = [
  {
    field: fields.assemblyServiceDateFinished,
    header: "Hora fin",
    category: modalCategories.ROLE_SECTION,
    readonly: false,
    displayable: false,
    dataTableType: datatableTypes.TEXT,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.serviceExtraHours,
    header: "Duración asamblea",
    category: modalCategories.ROLE_SECTION,
    readonly: true,
    displayable: false,
    dataTableType: datatableTypes.TEXT,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.managersQnty,
    header: "Cantidad operarios",
    category: modalCategories.ROLE_SECTION,
    readonly: true,
    displayable: false,
    dataTableType: datatableTypes.TEXT,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.managersBooked,
    header: "Operarios",
    category: modalCategories.ROLE_SECTION,
    readonly: true,
    displayable: false,
    dataTableType: datatableTypes.TEXT,
    modalType: gridItemsTypes.TEXT,
  },
];

export default {
  dataTableColumns,
  generalSection,
  reportsSection,
  roleSection,
};
