<template>
  <!-- <a href="/"> -->
  <img
    src="../../assets/images/Logo-alfa-white.png"
    width="44"
    height="52"
    alt="logo"
  />
  <!-- </a> -->
</template>
