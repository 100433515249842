<template>
  <div :class="getFullScreenClass" v-if="isVisible">
    <div class="grid place-items-center h-full">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  fullScreen: {
    type: Boolean,
    default: false,
  },
  fullRelative: {
    type: Boolean,
    default: false,
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const getFullScreenClass = computed(() => {
  return {
    "w-full h-screen": props.fullScreen,
    "absolute inset-0 flex items-center justify-center z-10 bg-[rgba(20,20,20,0.2)]":
      props.fullRelative,
  };
});
</script>

<style scoped>
.loader {
  width: 50px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#d95a11 0 0);
  background: var(--c) 0 0, var(--c) 0 100%, var(--c) 50% 0, var(--c) 50% 100%,
    var(--c) 100% 0, var(--c) 100% 100%;
  background-size: 8px 50%;
  animation: l7-0 1s infinite;
  position: relative;
  overflow: hidden;
}
.loader:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1a4a73;
  top: calc(50% - 4px);
  left: -8px;
  animation: inherit;
  animation-name: l7-1;
}

@keyframes l7-0 {
  16.67% {
    background-size: 8px 30%, 8px 30%, 8px 50%, 8px 50%, 8px 50%, 8px 50%;
  }
  33.33% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 50%, 8px 50%;
  }
  50% {
    background-size: 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 30%, 8px 30%;
  }
  66.67% {
    background-size: 8px 50%, 8px 50%, 8px 30%, 8px 30%, 8px 30%, 8px 30%;
  }
  83.33% {
    background-size: 8px 50%, 8px 50%, 8px 50%, 8px 50%, 8px 30%, 8px 30%;
  }
}

@keyframes l7-1 {
  20% {
    left: 0px;
  }
  40% {
    left: calc(50% - 4px);
  }
  60% {
    left: calc(100% - 8px);
  }
  80%,
  100% {
    left: 100%;
  }
}
</style>
