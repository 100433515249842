import fields from "../enums/field";
import modalCategories from "../enums/modalCategory";
import datatableTypes from "../enums/datatableTypes";
import modalTypes from "../enums/modalTypes";
import gridItemsTypes from "../enums/gridItemTypes";

const generalSection = [
  {
    field: fields.assemblyCotNumber,
    header: "COT",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.LINK,
    link: "https://joget.clasealpha.com:8443/jw/web/userview/acomercial2022/graficos/_/cot_final_crud?_mode=edit&id=",
  },
  {
    field: fields.assemblyClientAdvanceStatus,
    header: "Anticipo",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientOrganizationName,
    header: "Cliente",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyStatus,
    header: "Estado COT",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceType,
    header: "Modalidad",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.GROUPING,
  },
  {
    field: fields.assemblyServiceFirstAnnoucementDate,
    header: "Fecha 1ra convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceSecondAnnoucementDate,
    header: "Fecha 2da convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.isAssemblyClientInWallet,
    header: "Cartera",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceAddress,
    header: "Dirección",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientAdminName,
    header: "Administrador",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientPorterTel,
    header: "Tel porteria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyDateCreated,
    header: "Fecha que se agenda",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyApprovedBy,
    header: "Agendado por",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyClientAdminTel,
    header: "Celular de contacto",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.comercialSendReportsMail,
    header: "Correo al que se debe enviar informes",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceTotalPrice,
    header: "Valor cotizado",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyValuedParticipants,
    header: "Usuarios cotizados",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyRealParticipants,
    header: "Usuarios reales",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
];

const reportsSection = [];

const roleSection = [
  {
    field: "invoiceNumber",
    header: "Número",
    readonly: true,
    type: "link",
    link: "https://app.alegra.com/invoice/view/id/",
  },
  {
    field: "total",
    header: "invoiceTotal",
    readonly: true,
    type: "number",
  },
  {
    field: "invoiceTotalPaid",
    header: "Cobrado",
    readonly: true,
    type: "number",
  },
  {
    field: "invoiceDateIssued",
    header: "Creación",
    readonly: true,
    type: "date",
  },
  {
    field: "invoiceRefund",
    header: "Reembolso",
    readonly: false,
    type: "checkbox",
  },
  {
    field: "invoiceStatus",
    header: "Estado",
    readonly: true,
    type: "tag",
  },
  {
    field: "voided",
    header: "invoiceVoided",
    readonly: false,
    type: "checkbox",
  },
  {
    field: "invoiceMailSent",
    header: "Correo envía factura",
    readonly: false,
    type: "mail",
  },
];

export default {
  generalSection,
  reportsSection,
  roleSection,
};
