import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";

export function useDayjs() {
  dayjs.extend(localizedFormat);
  dayjs.locale("es");

  return dayjs;
}
