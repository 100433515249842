<template>
  <div class="flex right-0 mt-3 mr-5 gap-2">
    <FilterSidebar class="w-full" :class="hiddenClass"></FilterSidebar>
    <YearPicker class="w-full"></YearPicker>
  </div>
</template>
<script setup>
import FilterSidebar from "./FilterSidebar.vue";
import YearPicker from "./YearPicker.vue";
import { useRouter } from "vue-router";
import { computed } from "vue";

const router = useRouter();

const hiddenClass = computed(() => {
  const currentRoute = router.currentRoute.value.path;

  return {
    hidden: currentRoute !== "/calendar",
  };
});
</script>
