<template>
  <nav class="sidebar">
    <div v-if="isSidebarOpen" @click="closeSidebar"></div>
    <div
      class="sidebar__container bg-orange-500 transtion duration-300"
      :class="sidebarExtraclass"
    >
      <div class="sidebar__header justify-between">
        <LogoLink
          class="transtion duration-300"
          :class="{ 'w-5 ': isSidebarOpen }"
        />

        <SidebarButton @onClick="toggleSidebar" />
      </div>
      <div class="sidebar__body">
        <div class="sidebar__divider" />
        <SidebarMenu />
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed } from "vue";

import { useSidebar } from "../../composables/useSidebar";

import LogoLink from "./LogoLink.vue";
import SidebarButton from "./SidebarButton.vue";
import SidebarMenu from "./SidebarMenu.vue";

const { isSidebarOpen, toggleSidebar } = useSidebar();

const sidebarExtraclass = computed(() => {
  return {
    "sidebar__container--collapsed": !isSidebarOpen.value,
  };
});
</script>
