<template>
  <MenuList containerClass="nav-menu" listClass="nav-menu__list">
    <template v-for="item in menuItems" :key="item.key">
      <MenuItem
        itemClass="sidebar__item"
        activeItemClass="sidebar__item--active"
        :url="item.href"
        :icon="item.icon"
        :navText="item.label"
      />
    </template>
  </MenuList>
</template>

<script setup>
import MenuList from "./MenuList.vue";
import MenuItem from "./MenuItem.vue";
import { useNavLinks } from "../../composables/useNavlinks";

const { menuItems } = useNavLinks();
</script>
