<template>
  <!-- Contains roleSection, general outline -->
  <Section
    :title="roleUser[0].toUpperCase() + roleUser.substring(1)"
    :loading="isLoading"
  >
    <template #content>
      <Comercial
        v-if="roleUser === 'comercial'"
        :assemblyData="assemblyData"
        :permissions="data"
        @loadSection="onLoadSection"
      />
      <Contabilidad
        v-if="roleUser === 'contabilidad'"
        :assemblyData="assemblyData"
        :permissions="data"
        @loadSection="onLoadSection"
      />
      <Talento
        v-if="roleUser === 'talento'"
        :assemblyData="assemblyData"
        :permissions="data"
        @loadSection="onLoadSection"
      />
    </template>
  </Section>
</template>

<script setup>
const props = defineProps({
  assemblyData: {
    type: Object,
    default: {},
    required: true,
  },
  roleUser: {
    type: String,
    default: "",
    required: true,
  },
});

import { ref } from "vue";
import Section from "./modalItem/Section.vue";
import { useModalSection } from "../composables/useModalSection";
import Comercial from "./roleModalSection/Comercial.vue";
import Contabilidad from "./roleModalSection/Contabilidad.vue";
import Talento from "./roleModalSection/Talento.vue";

const isLoading = ref(false);

const { getRoleSectionData } = useModalSection();

const { data } = getRoleSectionData(props.roleUser);

const onLoadSection = (e) => {
  isLoading.value = e;
};
</script>
