<template>
  <div class="text-5xl text-gray-800 mt-4 font-bold ml-4 mb-2">Bienvenido</div>
  <div class="text-xs italic text-gray-500 ml-4">
    {{ randomPhrase.phrase }}
  </div>
  <div class="text-xs italic font-medium ml-4 mb-4">
    - {{ randomPhrase.author }}
  </div>
</template>
<script setup>
import { ref } from "vue";
import { randomMotivationalPhrase } from "../../utils/randomMotivationalPhrase";
let randomPhrase = ref(randomMotivationalPhrase());
</script>
