<template>
  <Section title="General" :loading="isLoading">
    <template #content>
      <GridItemsList>
        <template v-for="(header, index) in gridList" :key="index">
          <GeneralItem
            :contentExtraClass="getExtraContentClass(header.modalType)"
            :headerExtraClass="header.color"
          >
            <template #header>
              {{ header.header }}
            </template>

            <template #content>
              <div v-if="header.modalType === types.DATE_TEXT">
                {{
                  !!assemblyData[header.field]
                    ? dayjs(assemblyData[header.field]).format(
                        "dddd, D [de] MMM [a las] hh:mm a"
                      )
                    : "No hay fecha"
                }}
              </div>

              <div v-else-if="header.modalType === types.SIGNATURE">
                {{
                  signatures.find((sg) => sg.id === assemblyData[header.field])
                    ?.sig_name || ""
                }}
              </div>

              <a
                v-else-if="header.modalType === types.LINK"
                :href="header.link + assemblyData.assemblyId ?? ''"
                target="_blank"
              >
                {{ assemblyData[header.field] }}
              </a>

              <div v-else-if="header.modalType === types.GROUPING">
                {{
                  assemblyData[header.field]?.label ??
                  assemblyData[header.field]
                }}
              </div>

              <div
                v-else-if="
                  header.modalType === types.ICON_TAG ||
                  header.modalType === types.TAG
                "
                :class="backgroundIcontag(assemblyData[header.field] ?? null)"
              >
                <i
                  style="font-size: 0.7rem"
                  :class="icontag(assemblyData[header.field] ?? null)"
                >
                </i>
              </div>

              <div v-else>{{ assemblyData[header.field] }}</div>
            </template>
          </GeneralItem>
        </template>
      </GridItemsList>

      <WidgetList>
        <template v-for="(header, index) in widgetList" :key="index">
          <GeneralSectionWidget
            :classBackground="
              index % 2 === 0 ? '!bg-[#8C6B40] text-white' : 'bg-white'
            "
            :title="header.header"
            :content="assemblyData[header?.field] ?? ''"
          ></GeneralSectionWidget>
        </template>
      </WidgetList>

      <div
        class="text-center mt-3 text-xl font-bold text-gray-800"
        v-if="false"
      >
        Sobrecosto: {{ assemblyData.sobrecosto }}
      </div>

      <WidgetList
        justifyContent="justify-center"
        v-if="assemblyData.assemblyServiceTags"
      >
        <template v-for="(tag, index) in assemblyData.assemblyServiceTags">
          <GeneralServiceTag :label="tag?.label ?? ''"></GeneralServiceTag>
        </template>
      </WidgetList>
    </template>
  </Section>
</template>
<script setup>
const props = defineProps({
  assemblyData: Object,
  roleUser: String,
});

import dayjs from "dayjs";

import types from "../../enums/gridItemTypes";
import GeneralItem from "./generalSection/GeneralItem.vue";
import Section from "./Section.vue";
import WidgetList from "../aligment/WidgetList.vue";
import GeneralSectionWidget from "./generalSection/GeneralSectionWidget.vue";
import GeneralServiceTag from "./generalSection/GeneralServiceTag.vue";

import { useModalSection } from "../../composables/useModalSection";
import { getBackground, getIconV2 } from "../../utils/getIcon";
import GridItemsList from "../aligment/GridItemsList.vue";
import { useSignaturesStore } from "../../stores/signatures";
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { getSignatures } from "../../services/signatures";

const { getGeneralSectionData } = useModalSection();

const { gridList, widgetList } = getGeneralSectionData(props.roleUser);

const signaturesStore = useSignaturesStore();

const { signatures } = storeToRefs(signaturesStore);

const isLoading = ref(false);

const getExtraContentClass = (type) => {
  return {
    capitalize: type === "datetext",
    "font-bold !text-orange-500 hover:underline cursor-pointer":
      type === "link",
    "h-6 w-2 ml-2 rounded-full text-center content-center text-white":
      type === "icon_tag" || type === "tag",
  };
};

const backgroundIcontag = (status) => {
  return {
    "bg-red-600": status === "no_recibido",
    "bg-green-600": status === "recibido",
    "bg-[#8C6B40]": status === null,
  };
};

const icontag = (status) => {
  return {
    "pi pi-thumbs-down-fill": status === "no_recibido",
    "pi pi-thumbs-up-fill": status === "recibido",
    "bg-[#8C6B40]": status === null,
  };
};

onMounted(async () => {
  if (!signaturesStore.hasBeenLoaded()) {
    try {
      isLoading.value = true;
      const signatures = await getSignatures({ pageSize: 20 });
      signaturesStore.setSignatures(signatures.data);
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  }
});
</script>
