<template>
  <Chart type="doughnut" :data="chartData" :options="chartOptions" />
</template>
<script setup>
const props = defineProps({
  chartData: {
    type: Object,
    required: false,
    default: () => [],
  },
  chartOptionsConfig: {
    type: Object,
    default: {},
  },
});

import { ref, onMounted, watch } from "vue";
import Chart from "primevue/chart";
import _ from "lodash";

const chartOptions = ref({});

const setChartDataOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");

  const options = {
    plugins: {
      legend: {
        labels: {
          cutout: "60%",
          color: textColor,
        },
      },
    },
  };

  chartOptions.value = _.merge(options, props.chartOptionsConfig);
};

onMounted(() => {
  chartOptions.value = setChartDataOptions();
});

watch(
  () => props.chartData,
  () => setChartDataOptions()
);
</script>
