<template>
  <div class="w-full max-w-screen overflow-x-auto p-4">
    <div class="flex p-2 gap-2 items-center justify-end">
      <a :href="linkJogetFactura" target="_blank">
        <Button label="Facturar" link outlined />
      </a>

      <Button
        icon="pi pi-sync"
        severity="secondary"
        @click="updateDataInvoices"
        rounded
      />
    </div>
    <InvoicesTable
      :invoices="invoicesData"
      @loadTable="loadSectionFromTable"
    ></InvoicesTable>
  </div>

  <div class="flex items-center gap-2">
    <Button
      icon="pi pi-check"
      :class="invoiceBtnClass"
      :disabled="invoiceBtnDisabled"
      @click="updateAdvanceStatus"
      rounded
    />
    <div class="flex flex-col w-full">
      <div class="text-base font-semibold text-gray-800">Valor de anticipo</div>
      <InputNumber
        v-model="assemblyServiceAdvance"
        :disabled="invoiceBtnDisabled"
        inputId="minmaxfraction"
        :minFractionDigits="2"
        :maxFractionDigits="5"
        fluid
        @keyup.enter="updateAdvanceValue"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import InvoicesTable from "../invoices/invoicesSection/InvoicesTable.vue";

import InputNumber from "primevue/inputnumber";

import {
  getInvoicesByAssemblyCot,
  getInvoicesById,
  updateInvoiceJoget,
} from "../../services/invoices";
import { craftInvoices } from "../../workshop/invoices";
import { updateAssemblyClient } from "../../services/assembly";
import {
  getAdvancesByAssemblyNumber,
  updateAdvance,
} from "../../services/advances";
import { useItemDialog } from "../../composables/useItemDialog";
import { formatPaymentStatus } from "../../alchemy/invoices";

const props = defineProps({
  assemblyData: {
    type: Object,
    default: {},
    required: true,
  },
  permissions: {
    type: Object,
    default: {},
    required: true,
  },
});

const { updateAssemblyDataDialog } = useItemDialog();

const invoicesData = ref([]);

const assemblyServiceAdvance = ref(
  props.assemblyData?.assemblyServiceAdvance ?? 0
);

onMounted(async () => {
  try {
    emit("loadSection", true);

    const invoicesResource = await getInvoicesByAssemblyCot({
      cot: props.assemblyData?.assemblyCotNumber ?? "",
      pageSize: 200,
    });

    // Extra step because of lack of backend
    const craftedInvoices = craftInvoices(invoicesResource.data);

    invoicesData.value = craftedInvoices.map((invoice) => ({
      ...invoice,
      invoiceStatusLabel: formatPaymentStatus(invoice),
    }));
  } catch (error) {
    console.error(error);
  } finally {
    emit("loadSection", false);
  }
});

const loadSectionFromTable = (e) => {
  emit("loadSection", e);
};

const updateAdvanceStatus = async () => {
  if (props.assemblyData.assemblyClientAdvanceStatus === "confirmada") return;

  const order = ["no_recibido", "recibido", "confirmada"];
  const index = order.indexOf(props.assemblyData.assemblyClientAdvanceStatus);
  const nextStatus = order[index + 1];

  const requestData = {
    id: props.assemblyData?.assemblyCotNumber,
    advance_status: nextStatus,
  };

  try {
    emit("loadSection", true);

    const response = await updateAssemblyClient(requestData);
    console.log(response);
    updateAssemblyDataDialog(props.assemblyData?.assemblyId, {
      assemblyClientAdvanceStatus: nextStatus,
    });
  } catch (e) {
    console.error(e);
  } finally {
    emit("loadSection", false);
  }
};

const updateAdvanceValue = async () => {
  try {
    emit("loadSection", true);

    const assemblyAdvance = await getAdvancesByAssemblyNumber({
      cot: props.assemblyData?.assemblyCotNumber,
    });

    const idAdvance = assemblyAdvance.data?.[0].id;

    const requestData = {
      id: idAdvance,
      advance_value: assemblyServiceAdvance.value,
    };

    const response = await updateAdvance(requestData);
    console.log(response);
    updateAssemblyDataDialog(props.assemblyData?.assemblyId, {
      assemblyServiceAdvance: assemblyServiceAdvance.value,
    });
  } catch (e) {
    console.error(e);
  } finally {
    emit("loadSection", false);
  }
};

const invoiceBtnDisabled = computed(() => {
  return props.assemblyData.assemblyClientAdvanceStatus === "confirmada";
});

const invoiceBtnClass = computed(() => {
  return {
    "bg-green-600 border border-green-800":
      props.assemblyData.assemblyClientAdvanceStatus === "recibido",
    "bg-gray-400 border border-gray-600":
      props.assemblyData.assemblyClientAdvanceStatus === "no_recibido",
    "border border-gray-800":
      props.assemblyData.assemblyClientAdvanceStatus === "confirmada",
  };
});

const linkJogetFactura =
  import.meta.env.VITE_JOGET_BASE_URL +
    import.meta.env.VITE_LIST_CREATE_INVOICE +
    props.assemblyData?.assemblyCotNumber ?? "";

const updateDataInvoices = async () => {
  try {
    emit("loadSection", true);

    let updatedInvoices = [];
    for (const invoice of invoicesData.value) {
      const invoiceRequest = await getInvoicesById(invoice.invoiceIdAlegra);

      const invoiceAlegra = invoiceRequest.data?.[0] ?? {};

      const updatedInvoice = {
        number_invoice: invoiceAlegra.numberTemplate?.fullNumber ?? "",
        total: invoiceAlegra.total ?? 0,
        created: invoiceAlegra.date ?? null,
        paid: invoiceAlegra.totalPaid ?? 0,
        id_invoice: invoiceAlegra.id,
        voided: invoiceAlegra.status === "voided" ? 1 : 0,
        mail_invoice: invoice.invoiceMailSent,
        id: invoice.invoiceId,
        id_cot: invoice.invoiceAssemblyKey,
        status: invoiceAlegra.status,
        refund: invoiceAlegra.status === "refund" ? 1 : 0,
      };

      const responseUpdateJoget = await updateInvoiceJoget(updatedInvoice);

      updatedInvoices.push(updatedInvoice);
    }

    const craftedInvoices = craftInvoices(updatedInvoices);

    invoicesData.value = craftedInvoices.map((invoice) => ({
      ...invoice,
      invoiceStatusLabel: formatPaymentStatus(invoice),
    }));
  } catch (e) {
    console.error(e);
  } finally {
    emit("loadSection", false);
  }
};

const emit = defineEmits(["loadSection"]);
</script>
