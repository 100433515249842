import { JogetClient } from "../data/jogetClient";

export const getAdvances = (pageSize) => {
  return JogetClient.get(import.meta.env.VITE_LIST_ADVANCES, {
    params: { pageSize },
  });
};

export const getAdvancesByAssemblyNumber = ({ pageSize, cot }) => {
  if (!pageSize) pageSize = 10;
  if (!cot) return [];

  return JogetClient.get(import.meta.env.VITE_LIST_ADVANCES, {
    params: { c_id_cot: cot, pageSize },
  });
};

export const updateAdvance = (data) => {
  return JogetClient.put(import.meta.env.VITE_API_ANTICIPO, data);
};
