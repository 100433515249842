import { createRouter, createWebHistory } from "vue-router";

import Dashboard from "../views/DashboardView.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/comercial",
    name: "Comercial",
    component: () => import("../views/ComercialView.vue"),
  },
  {
    path: "/contabilidad",
    name: "Contabilidad",
    component: () => import("../views/Contabilidad.vue"),
  },
  {
    path: "/logistica",
    name: "Logistica",
    component: () => import("../views/LogisticaView.vue"),
  },
  {
    path: "/talentohumano",
    name: "TalentoHumano",
    component: () => import("../views/TalentoHumanoView.vue"),
  },
  {
    path: "/calendar",
    name: "Calendario",
    component: () => import("../views/CalendarView.vue"),
  },
  {
    path: "/actas",
    name: "Actas",
    component: () => import("../views/ActasView.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
