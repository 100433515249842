import managerEntity from "../entitites/manager";
import dayjs from "dayjs";
import _ from "lodash";

export const craftManagers = (resource) => {
  const craftedManagers = resource.reduce((acc, curr) => {
    let formattedManager = {};

    Object.keys(managerEntity).forEach((prop) => {
      const entityProp = managerEntity[prop];
      let resourceProp = curr[prop] ?? entityProp.default;

      const formattedValue = formatter(resourceProp, entityProp);

      if (formattedValue === undefined) {
        console.log("resourceProp: ", resourceProp);
        console.log("entityProp: ", entityProp);
        console.log("prop: ", prop);
        console.log("curr: ", curr);
      }

      formattedManager[entityProp.value] =
        formattedValue !== undefined ? formattedValue : resourceProp.default;
    });

    acc.push(formattedManager);
    return acc;
  }, []);

  return craftedManagers;
};

function formatter(value, formaterType) {
  if (formaterType.default === value) return value;

  if (formaterType.type === "text") {
    return value.toString();
  }
  if (formaterType.type === "date") {
    if (value === null) return null;
    const lowerCaseValue = value.toLowerCase().trim() ?? "";

    if (!lowerCaseValue) return null;

    const isValidDate = dayjs(lowerCaseValue, formaterType.format).isValid();

    if (!isValidDate) console.log(lowerCaseValue, formaterType.format);

    return isValidDate ? dayjs(lowerCaseValue, formaterType.format) : null;
  }
  if (formaterType.type === "number") {
    if (typeof value === "number") return value;
    const removedExtranousSymbols = value.replace(/[^0-9.]/g, "");

    return isNaN(parseInt(removedExtranousSymbols))
      ? 0
      : Number(removedExtranousSymbols);
  }
  if (formaterType.type === "array") {
    return _.isArray(value) ? value : value.split(";");
  } else return formaterType.default;
}
