<template>
  <Loader
    class="overflow-hidden"
    :isVisible="globlalStore.isLoading"
    :fullScreen="true"
  >
  </Loader>
</template>

<script setup>
import { useGlobalStore } from "../stores/global";
import Loader from "./Loader.vue";

const globlalStore = useGlobalStore();
</script>
