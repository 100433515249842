import { JogetClient } from "../data/jogetClient";

export const updateManagerAvailability = (data) => {
  return JogetClient.put(import.meta.env.VITE_API_TEMPORAL_AAA, data);
};

export const getManagersBooked = ({ pageSize }) => {
  if (!pageSize) pageSize = 500;

  return JogetClient.get(import.meta.env.VITE_LIST_OPERARIOS_AGENDADOS, {
    params: { pageSize },
  });
};

export const getManagersBookedByAssemblyNumber = ({ pageSize, cot }) => {
  if (!pageSize) pageSize = 40;
  if (!cot) return [];

  return JogetClient.get(import.meta.env.VITE_LIST_OPERARIOS_AGENDADOS, {
    params: { c_cot_number: cot, pageSize },
  });
};
