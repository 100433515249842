const gridItemsTypes = Object.freeze({
  DATE_TEXT: "datetext",
  LINK: "link",
  GROUPING: "grouping",
  ICON_TAG: "icon_tag",
  TAG: "tag",
  TEXT: "text",
  SIGNATURE: "signature",
});

export default gridItemsTypes;
