import { toRaw } from "vue";
import { useCotsStore } from "../stores/cots";
import _ from "lodash";

export function filterInvoicesWithAssembly(invoices) {
  const cotsStore = useCotsStore();
  const assemblies = toRaw(cotsStore.assemblies);

  const findInvoices = _.filter(invoices, (x) =>
    _.some(assemblies, (a) => a.assemblyCotNumber === x.invoiceAssemblyKey)
  );

  const foundInvoicesMapped = findInvoices.map((invoice) => ({
    ...invoice,
    invoiceStatusLabel: formatPaymentStatus(invoice),
  }));

  return foundInvoicesMapped;
}

export function getPaymentStatus(items) {
  let status = [];
  items.forEach((invoice) => status.push(invoice.invoiceStatusLabel));
  const counts = {};
  status.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1;
  });
  return counts;
}

export const formatPaymentStatus = (invoice) => {
  if (invoice.invoiceVoided === true) return "ANULADO";
  else if (invoice.invoiceStatus === "closed") return "PAGO";
  else if (invoice.invoiceStatus === "open")
    return invoice.invoiceTotalPaid === 0 ? "PENDIENTE" : "RESTA";
  else return "PENDIENTE";
};
