<template>
  <Section title="Zoom y observaciones" :loading="isLoading">
    <template #content>
      <!-- Zoom -->

      <IconItem
        itemIcon="pi pi-user"
        :itemContent="assemblyData.assemblyRoomMeetingUser"
      ></IconItem>
      <IconItem
        itemIcon="pi pi-video"
        :itemContent="assemblyData.assemblyRoomMeetingUrl"
      ></IconItem>

      <!-- Observaciones -->
      <Textarea id="observations" v-model="observations" rows="4" cols="70" />
      <Button
        icon="pi pi-save"
        severity="secondary"
        class="w-full"
        label="Guardar"
        @click="() => onEdit()"
      />
    </template>
  </Section>
</template>
<script setup>
const props = defineProps({
  assemblyData: Object,
});

import { ref } from "vue";
import Section from "./Section.vue";
import Textarea from "primevue/textarea";
import IconItem from "./footerSection/IconItem.vue";
import { updateAssembly } from "../../services/assembly";
import { useItemDialog } from "../../composables/useItemDialog";

const { updateAssemblyDataDialog } = useItemDialog();

const observations = ref(props.assemblyData?.assemblyRemarks ?? "");

const isLoading = ref(false);

const onEdit = async () => {
  if (props.assemblyData?.assemblyRemarks === observations.value.trim()) return;

  try {
    isLoading.value = true;
    const data = {
      id: props.assemblyData.assemblyId,
      observaciones: observations.value,
    };

    const response = await updateAssembly(data);

    updateAssemblyDataDialog(props.assemblyData.assemblyId, {
      assemblyRemarks: observations.value,
    });

    console.log(response);
  } finally {
    isLoading.value = false;
  }
};
</script>
