<template>
  <Chart type="bar" :data="chartData" :options="chartOptions" />
</template>

<script setup>
const props = defineProps({
  chartData: {
    type: Object,
    required: false,
    default: () => [],
  },
  chartOptionsConfig: {
    type: Object,
    default: {},
  },
});

import { ref, onMounted, watch } from "vue";
import Chart from "primevue/chart";
import _ from "lodash";

const chartOptions = ref({});

const setChartDataOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  const options = {
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  };

  chartOptions.value = _.merge(options, props.chartOptionsConfig);
};

onMounted(() => {
  chartOptions.value = setChartDataOptions();
});

watch(
  () => props.chartData,
  () => setChartDataOptions()
);
</script>
