<template>
  <div class="menu-list" :class="[containerClass, containerExtraClass]">
    <ul class="menu-list__list" :class="listClass">
      <slot />
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
  containerClass: String,
  listClass: [String, Array],
});

import { computed } from "vue";
import { useSidebar } from "../../composables/useSidebar";
const { isSidebarOpen } = useSidebar();

const containerExtraClass = computed(() => {
  return {
    "menu-list--collapsed": !isSidebarOpen.value,
  };
});
</script>
