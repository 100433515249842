import { ref } from "vue";
const isSidebarOpen = ref(false);
const isRightSidebarOpen = ref(false);
export function useSidebar() {
  function openSidebar() {
    isSidebarOpen.value = true;
  }

  function closeSidebar() {
    isSidebarOpen.value = false;
  }

  function toggleSidebar() {
    isSidebarOpen.value = !isSidebarOpen.value;
  }

  function openRightSidebar() {
    isRightSidebarOpen.value = true;
  }

  function closeRightSidebar() {
    isRightSidebarOpen.value = false;
  }

  function toggleRightSidebar() {
    isRightSidebarOpen.value = !isRightSidebarOpen.value;
  }

  return {
    isSidebarOpen,
    openSidebar,
    closeSidebar,
    toggleSidebar,
    closeRightSidebar,
    openRightSidebar,
    toggleRightSidebar,
    isRightSidebarOpen,
  };
}
