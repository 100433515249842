import { defineStore } from "pinia";

export const useInvoicesStore = defineStore("invoices", {
  state: () => ({
    invoices: [],
    loaded: false,
  }),
  actions: {
    setInvoices(invoices) {
      this.invoices = invoices;
      this.loaded = true;
    },
    hasBeenLoaded() {
      return this.loaded;
    },
  },
});
