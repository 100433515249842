<template>
  <div class="app-container" v-if="!globlalStore.isLoading">
    <Sidebar></Sidebar>
    <RightSidebar></RightSidebar>
    <div class="app-container__container">
      <Header class="app-container__header"></Header>
      <main class="app-container__content">
        <div class="relative">
          <Loader
            class="app-container__contentloader"
            :isVisible="globlalStore.isLoadingContent"
          />
        </div>
        <slot name="content"></slot>
      </main>
    </div>
  </div>
</template>

<script setup>
import Loader from "../components/Loader.vue";
import Sidebar from "../components/sidebar/Sidebar.vue";
import Header from "../components/header/Header.vue";
import { useGlobalStore } from "../stores/global";
import RightSidebar from "../components/header/RightSidebar.vue";

const globlalStore = useGlobalStore();
</script>
