<template>
  <Select
    v-model="globalStore.selectedYear"
    :options="yearsWithSupport"
    @change="onYearChange($event)"
    class="text-center"
  />
</template>
<script setup>
import Select from "primevue/select";
import { useGlobalStore } from "../../stores/global";
import { useYearData } from "../../composables/useYearData";
import { getAssemblies } from "../../services/assembly";
import { craftAssemblies } from "../../workshop/assembly";
import { useDropdownsStore } from "../../stores/dropdowns";
import { onMounted, toRaw } from "vue";
import { useSignaturesStore } from "../../stores/signatures";
import { useCotsStore } from "../../stores/cots";
import getAssemblyData from "../../alchemy/assembly";

const dropdownsStore = useDropdownsStore();
const signaturesStore = useSignaturesStore();
const cotsStore = useCotsStore();

const { setDefaultDate, yearsWithSupport } = useYearData();

const globalStore = useGlobalStore();

const onYearChange = async (year) => {
  globalStore.toggleLoading();
  try {
    const assembliesResource = await getAssemblies({
      pageSize: 100,
      year: year.value,
    });
    // Extra step because of lack of backend
    const assembliesCrafted = craftAssemblies(assembliesResource.data);

    const { dropdowns } = dropdownsStore;
    const { signatures } = signaturesStore;

    const assembliesToStore = getAssemblyData(assembliesCrafted, {
      signatures: toRaw(signatures),
      dropdowns: toRaw(dropdowns),
    });

    cotsStore.setAssemblies(assembliesToStore);
    cotsStore.startOffSet = 0;
  } finally {
    globalStore.toggleLoading();
    globalStore.selectedYear = year.value;
  }
};

// onMounted(() => setDefaultDate());
</script>
