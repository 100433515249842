import { defineStore } from "pinia";

export const useCotsStore = defineStore("cots", {
  state: () => ({
    totalItems: 0,
    assemblies: [],
    lastItem: {},
    startOffSet: 0,
  }),
  actions: {
    setAssemblies(assemblies) {
      this.assemblies = assemblies;
      this.totalItems = this.assemblies.length;
      this.lastItem = this.assemblies[this.totalItems - 1];
    },
    appendAssemblies(newAssemblies) {
      newAssemblies.forEach((x) => this.assemblies.push(x));

      this.totalItems = this.assemblies.length;
      this.lastItem = this.assemblies[this.totalItems - 1];
    },
    updateAssemblyStoreData(id, newContent) {
      const foundAssemblyIdx = this.assemblies.findIndex(
        (assembly) => assembly.assemblyId === id
      );

      if (foundAssemblyIdx === -1) return -1;

      const oldValue = this.assemblies[foundAssemblyIdx];

      const updatedAssembly = {
        ...oldValue,
        ...newContent,
      };

      this.assemblies[foundAssemblyIdx] = updatedAssembly;

      const newValue = this.assemblies[foundAssemblyIdx];

      return { oldValue, newValue };
    },
  },
  getters: {},
});
