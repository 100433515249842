<script setup>
const props = defineProps({
  icon: {
    type: String,
    required: true,
    default: () => "pi pi-table",
  },
  title: {
    type: String,
    required: true,
    default: () => "Total",
  },
  value: {
    required: true,
    default: {},
  },
});
</script>

<template>
  <div class="bg-gray-100 shadow rounded-lg p-4 ml-2">
    <!-- Total Billed Title -->
    <div class="flex">
      <i :class="props.icon" class="mr-2"></i>
      <div class="text-gray-600 text-sm uppercase font-bold">
        {{ props.title }}
      </div>
    </div>

    <!-- Total Billed Amount -->
    <div class="text-2xl text-gray-800 mt-2 mb-2">
      {{ !isNaN(props.value.total) ? "$" : "" }}
      {{
        !isNaN(props.value.total)
          ? props.value.total.toLocaleString()
          : props.value.total
      }}
    </div>
    <hr />
    <!-- Percentage Change -->
    <div class="mt-2" v-if="!!props.value.growth">
      <span
        :class="props.value.growth < 0 ? 'text-red-600' : 'text-green-600'"
        class="text-sm font-semibold"
      >
        <i
          :class="
            props.value.growth < 0
              ? 'pi pi-arrow-down-right'
              : 'pi pi-arrow-up-right'
          "
        ></i>
        {{ props.value.growth }}%
      </span>
      <span class="text-gray-500 text-sm"> año anterior </span>
    </div>
  </div>
</template>
