import calendarPermissions from "../permissions/CalendarColumn";
import comercialPermissions from "../permissions/ComercialColumn";
import contabilidadPermissions from "../permissions/ContabilidadColumn";
import logisticaPermissions from "../permissions/LogisticaColumn";
import talentoPermissions from "../permissions/TalentoHumanoColumn";

import modalCategories from "../enums/modalCategory";

export function useModalSection() {
  const permissionsMap = {
    comercial: comercialPermissions,
    contabilidad: contabilidadPermissions,
    logistica: logisticaPermissions,
    talento: talentoPermissions,
    default: calendarPermissions,
  };

  function getGeneralSectionData(user) {
    const columns =
      (permissionsMap[user] || permissionsMap.default).generalSection ?? [];

    const gridList = getAvailableCategoryFromColumns(
      columns,
      modalCategories.GENERAL_SECTION__GRID
    );
    const widgetList = getAvailableCategoryFromColumns(
      columns,
      modalCategories.GENERAL_SECTION__WIDGETLIST
    );

    return {
      gridList,
      widgetList,
    };
  }

  function getReportsSectionData(user) {
    const columns =
      (permissionsMap[user] || permissionsMap.default).reportsSection ?? [];

    return {
      inputs: columns,
    };
  }

  function getRoleSectionData(user) {
    const columns =
      (permissionsMap[user] || permissionsMap.default).roleSection ?? [];

    return {
      data: columns,
    };
  }

  function getAvailableCategoryFromColumns(columns, category) {
    return columns.filter((el) => el.category === category) || [];
  }

  return {
    getGeneralSectionData,
    getReportsSectionData,
    getRoleSectionData,
  };
}
