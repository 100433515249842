export default {
  number_invoice: {
    default: "",
    value: "invoiceNumber",
    type: "text",
  },
  total: {
    default: 0,
    value: "invoiceTotal",
    type: "number",
  },
  created: {
    default: null,
    value: "invoiceDateIssued",
    type: "date",
    format: "YYYY-MM-DD",
  },
  paid: {
    default: 0,
    value: "invoiceTotalPaid",
    type: "number",
  },
  id_invoice: {
    default: "",
    value: "invoiceIdAlegra",
    type: "text",
  },
  mail_invoice: {
    default: "",
    value: "invoiceMailSent",
    type: "text",
  },
  voided: {
    default: false,
    value: "invoiceVoided",
    type: "boolean",
  },
  id: {
    default: "",
    value: "invoiceId",
    type: "text",
  },
  id_cot: {
    default: "",
    value: "invoiceAssemblyKey",
    type: "text",
  },
  status: {
    default: "",
    value: "invoiceStatus",
    type: "text",
  },
  refund: {
    default: false,
    value: "invoiceRefund",
    type: "boolean",
  },
};
