<template>
  <li class="menu-list__item-container">
    <div
      class="menu-list__item"
      :class="[itemClass, extraClass]"
      @click="onClick"
    >
      <div v-if="activeRoute" class="sidebar__active-bar" />
      <i :class="icon" class="sidebar__item-icon"></i>
      <span
        class="sidebar__item-text"
        :class="{ 'sidebar__item-text--collapsed': !isSidebarOpen }"
        >{{ navText }}</span
      >
    </div>
  </li>
</template>

<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  itemClass: String,
  activeItemClass: String,
});

// const route = useRoute();
// const { isOpenSidebar, closeSidebar } = useOpenSidebar();

import { computed } from "vue";
import { useRouter } from "vue-router";
import { useSidebar } from "../../composables/useSidebar";

const { isSidebarOpen, closeSidebar } = useSidebar();
const router = useRouter();

const onClick = () => {
  closeSidebar();
  router.push(props.url);
};

const activeRoute = computed(() => {
  return props.url === router.currentRoute.value.path;
});

const extraClass = computed(() => {
  if (!props?.url || !props?.activeItemClass) return {};

  const currentRoute = router.currentRoute.value.path;

  return {
    [props.activeItemClass]: currentRoute === props.url,
  };
});
</script>
