const fields = Object.freeze({
  comercialSendReportsMail: "comercialSendReportsMail",
  version: "version",
  reportsRemarks: "reportsRemarks",
  assemblyParticipants: "assemblyParticipants",
  isAssemblyClientInWallet: "isAssemblyClientInWallet",
  assemblyServiceType: "assemblyServiceTypeFormatted",
  assemblyClientOrganizationName: "assemblyClientOrganizationName",
  assemblyStatus: "assemblyStatusFormatted",
  assemblyServiceClientMail: "assemblyServiceClientMail",
  assemblyServiceAdvance: "assemblyServiceAdvance",
  reportsReviewedBy: "reportsReviewedBy",
  assemblyClientPorterTel: "assemblyClientPorterTel",
  assemblyClientAdvanceStatus: "assemblyClientAdvanceStatusFormatted",
  assemblyId: "assemblyId",
  assemblyCotNumber: "assemblyCotNumberFormatted",
  assemblyServiceAddress: "assemblyServiceAddress",
  assemblyServiceSecondAnnoucementDate:
    "assemblyServiceSecondAnnoucementDateFormatted",
  assemblyServiceTags: "assemblyServiceTags",
  reportsSendWoPayment: "reportsSendWoPayment",
  assemblyRoomMeetingUser: "assemblyRoomMeetingUser",
  assemblyClientAdminName: "assemblyClientAdminName",
  assemblyServiceTotalPrice: "assemblyServiceTotalPrice",
  invoicesMailShouldSend: "invoicesMailShouldSend",
  assemblyRemarks: "assemblyRemarks",
  assemblyServiceDateFinished: "assemblyServiceDateFinished",
  assemblyServiceReportsMail: "assemblyServiceReportsMail",
  assemblyDateCreated: "assemblyDateCreatedFormatted",
  reportsSentBy: "reportsSentBy",
  assemblyClientAdminTel: "assemblyClientAdminTel",
  assemblyServiceFirstAnnoucementDate:
    "assemblyServiceFirstAnnoucementDateFormatted",
  assemblyRoomMeetingUrl: "assemblyRoomMeetingUrl",
  assemblyLogisticaStatus: "assemblyLogisticaStatus",
  assemblyComercialStatus: "assemblyComercialStatus",
  assemblyContabilidadStatus: "assemblyContabilidadStatus",
  assemblyTalentoStatus: "assemblyTalentoStatus",
  assemblyApprovedBy: "assemblyApprovedByFormatted",
  assemblyServiceRecordQnty: "assemblyServiceRecordQnty",
  assemblyServiceDriverQnty: "assemblyServiceDriverQnty",
  assemblyServiceVirtualQnty: "assemblyServiceVirtualQnty",
  assemblyServiceConnectionQnty: "assemblyServiceConnectionQnty",
  assemblyServiceSoundQnty: "assemblyServiceSoundQnty",
  assemblyServiceRegisterQnty: "assemblyServiceRegisterQnty",
  reservedData: "reservedData",
  managersBooked: "managersBooked",
  managersQnty: "managersQnty",
  serviceExtraHours: "serviceExtraHours",
  assemblyRealParticipants: "assemblyRealParticipants",
  assemblyValuedParticipants: "assemblyValuedParticipants",
  reportsDateSend: "reportsDateSend",
});

export default fields;
