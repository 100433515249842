import assemblyEntity from "../entitites/assembly";
import { useDayjs } from "../composables/useDayjs";
import _ from "lodash";

const dayjs = useDayjs();

export const craftAssemblies = (resource) => {
  const craftedAssemblies = resource.reduce((acc, curr) => {
    let formattedAssembly = {};

    /**
     *
     * La manera en la que se clasificaban los servicios en joget era por medio de la prop: `c_eleccion_servicio` hasta el 31 diciembre 2023, luego se empezó a utilizar un sistema de tags.
     *
     * El contenido de `c_eleccion_servicio` fue reemplazado por `c_service_tags`
     *
     * `c_eleccion_servicio` ahora contiene cuatro valores: [ 'Servicio', 'Alquiler', 'Reseller', 'Venta' ], sin embargo, pueden añadirse.
     *
     */

    // v1 service clasification manteinance

    const resourceCurr = v1EntityCrafting(curr);

    Object.keys(assemblyEntity).forEach((prop) => {
      const entityProp = assemblyEntity[prop];
      let resourceProp = resourceCurr[prop] ?? entityProp.default;

      const formattedValue = formatter(resourceProp, entityProp);

      if (formattedValue === undefined) {
        console.log("resourceProp: ", resourceProp);
        console.log("entityProp: ", entityProp);
        console.log("prop: ", prop);
        console.log("resourceCurr: ", resourceCurr);
      }

      formattedAssembly[entityProp.value] =
        formattedValue !== undefined ? formattedValue : resourceProp.default;
    });

    acc.push(formattedAssembly);
    return acc;
  }, []);

  //   Order descendant dates
  return craftedAssemblies.sort((a, b) => {
    let currentDate = dayjs();
    let dateA = a.assemblyServiceFirstAnnoucementDate;
    let dateB = b.assemblyServiceFirstAnnoucementDate;

    // Verificar si 'a' ya pasó
    let aHasPassed = dateA.isBefore(currentDate);

    // Verificar si 'b' ya pasó
    let bHasPassed = dateB.isBefore(currentDate);

    // Si ambos tienen el mismo estado (ambos pasados o ambos futuros), mantener orden por fecha
    if (aHasPassed === bHasPassed) {
      return dateA.isAfter(dateB) ? 1 : -1;
    }

    // Si 'a' pasó pero 'b' no, 'a' va después de 'b'
    // Si 'b' pasó pero 'a' no, 'a' va antes de 'b'
    return aHasPassed ? 1 : -1;
  });
};

function formatter(value, formaterType) {
  // if (formaterType.default === value) return value;

  if (formaterType.type === "text") {
    return value.toString();
  }
  if (formaterType.type === "date") {
    // if (value === formaterType.default) return value;

    // console.log(value);

    const lowerCaseValue = value.toLowerCase().trim() ?? "";

    if (!lowerCaseValue) return null;

    const parsedDate = parseDate(lowerCaseValue, formaterType.format);

    if (parsedDate === null) {
      console.log(lowerCaseValue, formaterType.format, parsedDate);
    }

    return parsedDate === null ? parsedDate : dayjs(parsedDate);
  }
  if (formaterType.type === "number") {
    if (value === formaterType.default) return value;
    const removedExtranousSymbols = value.replace(/[^0-9.]/g, "");

    return isNaN(parseInt(removedExtranousSymbols))
      ? 0
      : Number(removedExtranousSymbols);
  }
  if (formaterType.type === "array") {
    return _.isArray(value) ? value : value.split(";");
  } else return formaterType.default;
}

// Dayjs couldn't handle it, so date formatting were selfmade

function parseDate(lowerCaseValue, format) {
  if (format === "YYYY-MM-DD HH:mm:ss.S") {
    return dayjs(lowerCaseValue, format);
  }

  const [datePart, ...timeParts] = lowerCaseValue.split(" ");
  const timePart = timeParts.join(" ");

  const [day, month, year] = datePart.split("-").map(Number);

  let hours = 0,
    minutes = 0;
  if (timePart && format.includes("hh:mm a")) {
    const [time, period] = timePart.split(" ");
    const [h, m] = time.split(":").map(Number);
    hours = h;
    minutes = m;

    if (period?.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (period?.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }
  }

  const date = new Date(year, month - 1, day, hours, minutes);

  if (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  ) {
    return date;
  }

  return null;
}

function v1EntityCrafting(resource) {
  const version = getVersionAsamblea(resource.c_eleccion_servicio);

  let newResource = resource;
  if (version === "v1") {
    newResource.c_service_tags = [];
  }
  newResource.version = version;

  return newResource;
}

function getVersionAsamblea(eleccionservicio) {
  const Oldservices = [
    "asamblea_virtual",
    "asamblea_presencial",
    "asamblea_mixta",
    "solo_sonido_completo",
    "relatoria_acta",
    "indumentaria",
    "navidad",
    "videobeam_pantalla",
    "solo_videobeam",
    "asistencia+votación",
    "fiestas_halloween",
    "asamblea_flexible",
  ];
  if (_.includes(Oldservices, eleccionservicio)) return "v1";
  else return "v2";
}
