import { ref } from "vue";
import { calculateDataDashboard, getReportsStatus } from "../alchemy/dashboard";
import { useDayjs } from "./useDayjs";
import { useDropdownsStore } from "../stores/dropdowns";

export function useDashboard() {
  const dayjs = useDayjs();
  const dropdownsStore = useDropdownsStore();

  function setDashboardData(assemblies) {
    const dropdowns = dropdownsStore.dropdowns;
    const assembliesLenght = assemblies.length;

    const assembliesInOrder = assemblies.sort((a, b) => {
      return dayjs(a.assemblyServiceFirstAnnoucementDate).isBefore(
        dayjs(b.assemblyServiceFirstAnnoucementDate)
      )
        ? -1
        : 1;
    });

    const firstDashboardDateAssembly = dayjs(
      assembliesInOrder[0].assemblyServiceFirstAnnoucementDate
    ).format("D [de] MMMM [del] YYYY");

    const lastDashboardDateAssembly = dayjs(
      assembliesInOrder[assembliesLenght - 1]
        .assemblyServiceFirstAnnoucementDate
    ).format("D [de] MMMM [del] YYYY");

    let dashboardData = calculateDataDashboard(assemblies, assembliesLenght);

    const mostPopularService = getDropdownMostPopularService(
      dropdowns,
      dashboardData.mostPopularService?.total ?? ""
    );

    dashboardData.mostPopularService.total =
      mostPopularService.label ?? dashboardData.mostPopularService.total;

    return {
      firstdayCalculated: firstDashboardDateAssembly,
      lastdayCalculated: lastDashboardDateAssembly,
      dashboardData,
    };
  }

  function setReportsData(assemblies) {
    return getReportsStatus(assemblies);
  }

  const dashboardItems = ref([
    {
      icon: "pi pi-money-bill",
      title: "Facurado aprobado",
      key: "totalInvoicedApproved",
      amount: 0,
      percentage: 0,
    },
    {
      icon: "pi pi-money-bill",
      title: "Facurado en espera",
      key: "totalInvoicedWaiting",
      amount: 0,
      percentage: null,
    },
    {
      icon: "pi pi-shopping-bag",
      title: "Total anticipos",
      key: "totalAdvances",
      amount: 0,
      percentage: 0,
    },
    {
      icon: "pi pi-star-fill",
      title: "Servicio más pedido",
      key: "mostPopularService",
      amount: "...",
      percentage: null,
    },
  ]);

  const externalLinksItems = ref([
    {
      url: "https://app.alegra.com/",
      key: "alegra",
      image: "https://files.readme.io/3727fad-logo-alegra-white.svg",
      bgColor: "!bg-[#03D5B7]",
    },
    {
      url: "https://joget.clasealpha.com:8443/jw",
      key: "jogetdx",
      image:
        "https://www.joget.org/wp-content/uploads/2023/07/Joget_logo_new_Horizontal_Green.png",
      bgColor: "!bg-[#FFF]",
    },
  ]);

  return {
    externalLinksItems,
    dashboardItems,
    setDashboardData,
    setReportsData,
  };
}

const getDropdownMostPopularService = (dropdowns, value) => {
  let v;

  v = dropdowns.find(
    (dp) => dp.grouping === "tag_service_type" && dp.value === value
  );

  if (!v) {
    v = dropdowns.find(
      (dp) => dp.grouping === "service_type" && dp.value === value
    );
  }

  return v ?? value;
};
