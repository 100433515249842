export const randomMotivationalPhrase = () => {
  const phrases =[
    {
      phrase: "La persistencia es el camino al éxito.",
      author: "Charles Chaplin",
    },
    {
      phrase:
        "El éxito es la suma de pequeños esfuerzos repetidos día tras día.",
      author: "Robert Collier",
    },
    {
      phrase: "No cuentes los días, haz que los días cuenten.",
      author: "Muhammad Ali",
    },
    {
      phrase: "El único modo de hacer un gran trabajo es amar lo que haces.",
      author: "Steve Jobs",
    },
    {
      phrase:
        "No hay nada imposible, porque los sueños de ayer son las esperanzas de hoy y pueden convertirse en realidad mañana.",
      author: "William Arthur Ward",
    },
    {
      phrase: "La mejor forma de predecir el futuro es inventarlo.",
      author: "Alan Kay",
    },
    {
      phrase: "Si puedes soñarlo, puedes hacerlo.",
      author: "Walt Disney",
    },
    {
      phrase:
        "El éxito no es definitivo, el fracaso no es fatal: lo que cuenta es el valor para continuar.",
      author: "Winston Churchill",
    },
    {
      phrase:
        "Cree en ti mismo y todo lo que eres. Reconoce que hay algo dentro de ti que es más grande que cualquier obstáculo.",
      author: "Christian D. Larson",
    },
    {
      phrase: "No esperes. Nunca será el momento adecuado.",
      author: "Napoleón Hill",
    },
    {
      phrase: "Empieza donde estás, usa lo que tienes, haz lo que puedes.",
      author: "Arthur Ashe",
    },
    {
      phrase:
        "El éxito no se mide en el dinero, sino en la diferencia que haces en las personas.",
      author: "Michelle Obama",
    },
    {
      phrase:
        "El futuro pertenece a aquellos que creen en la belleza de sus sueños.",
      author: "Eleanor Roosevelt",
    },
    {
      phrase:
        "Haz hoy lo que otros no quieren, haz mañana lo que otros no pueden.",
      author: "Jerry Rice",
    },
    {
      phrase: "La medida de lo que somos es lo que hacemos con lo que tenemos.",
      author: "Vince Lombardi",
    },
    {
      phrase: "Siempre parece imposible hasta que se hace.",
      author: "Nelson Mandela",
    },
  ];
  const randomIndex = Math.floor(Math.random() * phrases.length);
  return phrases[randomIndex];
};
