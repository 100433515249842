import { ref } from "vue";
import { useGlobalStore } from "../stores/global";

export function useYearData() {
  const yearsWithSupport = ref(["2022", "2023", "2024", "2025"]);

  function setDefaultDate() {
    const globalStore = useGlobalStore();
    globalStore.selectedYear = new Date().getFullYear().toString();
  }

  return { yearsWithSupport, setDefaultDate };
}
