import { JogetClient } from "../Data/jogetClient";

export const getReports = (pageSize) => {
  return JogetClient.get(import.meta.env.VITE_API_REPORTES, {
    params: { pageSize },
  });
};

export const updateReport = (data) => {
  return JogetClient.put(import.meta.env.VITE_API_CREATE_REPORT, data);
};
