<template>
  <WelcomeCard />

  <div class="px-4 flex gap-2">
    Información desde el
    <div class="underline text-orange-600">
      {{ firstDay }}
      <i class="pi pi-pencil pl-2 cursor-pointer"></i>
    </div>
    hasta el
    <div class="underline text-orange-600">
      {{ lastDay }}
      <i class="pi pi-pencil pl-2 cursor-pointer"></i>
    </div>
  </div>

  <div class="flex">
    <WidgetList class="w-5/6">
      <template v-for="item in dashboardItems" :key="item.key">
        <DashboardWidget
          class="w-full"
          :icon="item.icon"
          :title="item.title"
          :value="data[item.key] ?? item.amount"
        ></DashboardWidget>
      </template>
    </WidgetList>

    <div class="w-1/6">
      <ExternalButtonList>
        <template v-for="item in externalLinksItems" :key="item.key">
          <ExternalButton
            :url="item.url"
            :image="item.image"
            :itemClass="item.bgColor"
          ></ExternalButton>
        </template>
      </ExternalButtonList>
    </div>
  </div>

  <div class="flex p-2 gap-2">
    <PaymentStatusChart class="w-4/6" />
    <ReportStatusChart class="w-2/6" />
  </div>
</template>

<script setup>
import WelcomeCard from "../components/dashboard/WelcomeCard.vue";

import DashboardWidget from "../components/dashboard/DashboardWidget.vue";
import WidgetList from "../components/aligment/WidgetList.vue";

import ExternalButton from "../components/dashboard/ExternalButton.vue";
import ExternalButtonList from "../components/dashboard/ExternalButtonList.vue";

import PaymentStatusChart from "../components/dashboard/PaymentStatusChart.vue";
import ReportStatusChart from "../components/dashboard/ReportStatusChart.vue";

import { useDashboard } from "../composables/useDashboard";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useCotsStore } from "../stores/cots";

const cotsStore = useCotsStore();

const { dashboardItems, externalLinksItems, setDashboardData } = useDashboard();

const data = ref([]);
const firstDay = ref("...");
const lastDay = ref("...");

onMounted(() => {
  const { assemblies } = storeToRefs(cotsStore);

  let { dashboardData, firstdayCalculated, lastdayCalculated } =
    setDashboardData(assemblies.value);

  data.value = dashboardData;
  firstDay.value = firstdayCalculated;
  lastDay.value = lastdayCalculated;
});
</script>
