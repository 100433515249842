import axios from "axios";

const JogetClient = axios.create({
  baseURL: import.meta.env.VITE_JOGET_BASE_URL,
  /** Las respuestas del servidor son muy lentas  */
  // timeout: 20000,
  headers: {
    accept: "application/json",
    api_id: import.meta.env.VITE_API_ID,
    api_key: import.meta.env.VITE_API_KEY,
    "Content-Type": "application/json",
  },
});

JogetClient.interceptors.response.use(
  (response) => ({
    data: response.data.data,
    size: response.data?.data?.length ?? 0,
  }),
  (error) => {
    let errorData = error;

    if (error.response?.data) {
      errorData = error.response.data;
    }

    return Promise.reject(errorData);
  }
);

export { JogetClient };
