import _ from "lodash";
import { defineStore } from "pinia";

export const useDialogStore = defineStore("dialog", {
  state: () => ({
    isVisible: false,
    title: null,
    titleIcon: null,
    contentClass: null,
    headerClass: null,
    titleIconExtraClass: "hidden",
    assemblyData: null,
    roleUser: "general",
  }),
  actions: {
    openModal(options, assemblyData) {
      this.isVisible = true;

      this.$state = _.merge(this.$state, options);

      this.assemblyData = assemblyData;
    },
    closeModal(options) {
      this.$state = options;
    },
    toggleModal() {
      this.isVisible = !this.isVisible;
    },
    updateAssemblyData(newContent) {
      this.assemblyData = newContent;
    },
  },
});
