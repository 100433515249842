export default {
  c_c_full_name: {
    default: "",
    value: "managerName",
    type: "text",
  },
  c_bgcolor: {
    default: "",
    value: "managerColorStatus",
    type: "text",
  },
  c_confirm: {
    default: "",
    value: "managerConfirmed",
    type: "text",
  },
  c_cot_number: {
    default: "",
    value: "managerAssemblyKey",
    type: "text",
  },
  id: {
    default: "",
    value: "managerId",
    type: "text",
  },
  c_funcion: {
    default: "",
    value: "managerFunction",
    type: "text",
  },
};
