import { defineStore } from "pinia";

export const useSignaturesStore = defineStore("signatures", {
  state: () => ({
    signatures: [],
    loaded: false,
  }),
  actions: {
    setSignatures(sg) {
      this.signatures = sg;
      this.loaded = true;
    },
    hasBeenLoaded() {
      return this.loaded;
    },
  },
});
