import { ref } from "vue";

export function useProgressCot() {
  const events = ref([
    {
      name: "Comercial",
      key: "assemblyComercialStatus",
      status: false,
    },
    {
      name: "Logística",
      key: "assemblyLogisticaStatus",
      status: false,
    },
    {
      name: "Talento",
      key: "assemblyTalentoStatus",
      status: false,
    },
    {
      name: "Contabilidad",
      key: "assemblyContabilidadStatus",
      status: false,
    },
  ]);

  return { events };
}
