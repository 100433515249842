<template>
  <Card
    :pt="{
      root: { class: '!bg-[#FFCA80]' },
      title: { class: 'ml-2 text-xl text-white uppercase font-bold' },
    }"
  >
    <template #title>
      <i class="pi pi-th-large"></i>
      Informes
      <hr class="bg-white mt-2 h-0.5" />
    </template>
    <template #content>
      <div class="flex justify-center h-full">
        <DonutChart
          :chartData="chartData"
          :chartOptionsConfig="chartOptionsConfig"
        />
      </div>
    </template>
  </Card>
</template>
<script setup>
const props = defineProps({
  chartOptionsConfig: {
    type: Object,
    default: {},
  },
});

import { onMounted, ref, watch } from "vue";
import DonutChart from "../chart/DonutChart.vue";
import { useGlobalStore } from "../../stores/global";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { useCotsStore } from "../../stores/cots";
import { useDashboard } from "../../composables/useDashboard";

const globalStore = useGlobalStore();
const cotsStore = useCotsStore();
const { selectedYear } = globalStore;
const reportsData = ref({});

const chartData = ref({});

const getChartDatalabels = () => {
  let dataLabels = [];
  let dataValues = [];

  const dataset = reportsData.value ?? {};

  let colors = ["rgb(255, 255, 255)", "rgb(255, 153, 0)", "rgb(140, 107, 64)"];

  let idxValuesPushed = 0;

  for (const i in dataset) {
    ++idxValuesPushed;
    dataValues.push(dataset[i]);
    if (idxValuesPushed >= 4) break;
  }
  for (let i = 3; i <= dataValues.length - 1; i++) {
    colors.push(`rgb(255, ${153 + i * 10}, ${10 * i})`);
  }

  dataLabels = _.take(Object.keys(dataset), 5);

  return { dataLabels, dataValues, colors };
};

const setChartData = () => {
  const { dataLabels, dataValues, colors } = getChartDatalabels();

  chartData.value = {
    labels: dataLabels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };
};

onMounted(() => {
  const { assemblies } = storeToRefs(cotsStore);
  reportsData.value = useDashboard().setReportsData(assemblies.value);
  setChartData();
});

watch(
  () => selectedYear,
  () => setChartData()
);
</script>
