<template>
  <DataTable scrollable :loading="isLoading" :value="invoices">
    <!-- -->
    <template #empty> No hay facturas registradas. </template>
    <template #loading> Actualizando... </template>

    <Column
      field="invoiceNumber"
      header="Número"
      class="text-orange-800"
      :pt="{
        bodyCell: {
          class: 'cursor-pointer hover:underline hover:text-orange-500 ',
        },
      }"
    >
      <template #body="{ data }">
        <div @click="openInvoiceNewTab(data.invoiceIdAlegra)">
          {{ data.invoiceNumber ?? "Ver" }}
        </div>
      </template>
    </Column>

    <Column field="invoiceTotal" header="Total">
      <template #body="{ data }">
        $
        {{
          data.invoiceTotal?.toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) ?? 0
        }}
      </template>
    </Column>

    <Column field="invoiceTotalPaid" header="Cobrado">
      <template #body="{ data }">
        $
        {{
          data.invoiceTotalPaid?.toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }) ?? 0
        }}
      </template>
    </Column>

    <Column field="invoiceDateIssued" header="Creado">
      <template #body="{ data }">
        {{ dayjs(data.invoiceDateIssued).format("D[,] MMMM [del] YYYY") }}
      </template>
    </Column>

    <Column field="invoiceVoided" header="Reembolsado">
      <template #body="{ data }">
        <Checkbox v-model="data.invoiceVoided" binary disabled />
      </template>
    </Column>

    <Column field="invoiceStatus" header="Estado">
      <template #body="{ data }">
        <Tag
          :value="data.invoiceStatusLabel"
          :severity="getInvoiceStatus(data)"
        ></Tag>
      </template>
    </Column>

    <Column field="invoiceRefund" header="Anulado">
      <template #body="{ data }">
        <Checkbox v-model="data.invoiceRefund" binary disabled />
      </template>
    </Column>

    <Column field="invoiceMailSent" header="Correo envía factura">
      <template #body="{ data }">
        <InputText
          v-model="data.invoiceMailSent"
          @keyup.enter="updateInvoiceMailSent(data)"
        ></InputText>
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
import { computed, ref } from "vue";
import { useDayjs } from "../../../composables/useDayjs";

import Checkbox from "primevue/checkbox";
import Tag from "primevue/tag";
import { updateInvoiceJoget } from "../../../services/invoices";

const dayjs = useDayjs();

const props = defineProps({
  invoices: {
    type: Object,
    required: true,
    default: () => [],
  },
});

const emit = defineEmits(["loadTable"]);

const isLoading = ref(false);

const openInvoiceNewTab = (number) => {
  window.open(`https://app.alegra.com/invoice/view/id/${number}`, "_blank");
};

const getInvoiceStatus = (e) => {
  switch (e.invoiceStatusLabel) {
    case "PENDIENTE":
      return "danger";
    case "RESTA":
      return "warn";
    case "PAGO":
      return "success";
  }
};

const updateInvoiceMailSent = async (invoice) => {
  const requestData = {
    id: invoice.invoiceId,
    mail_invoice: invoice.invoiceMailSent,
  };

  try {
    isLoading.value = true;
    const response = await updateInvoiceJoget(requestData);

    console.log(response);
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};
</script>
